import * as React from "react";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import QuotesBlock from "../components/QuotesBlock";
import VideoBlock from "../components/VideoBlock";
import TourBlock from "../components/TourBlock";
import ReleasesBlock from "../components/ReleasesBlock";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <QuotesBlock limit={5}
        headline="press"
        seeMoreUrl="/about"
        seeMoreText="read more" />
      <VideoBlock limit={1}
        seeMoreUrl="/visuals"
        seeMoreText="watch more" />
      <TourBlock limit={3}
        seeMoreUrl="/tour"
        seeMoreText="see more tour dates" />
      <ReleasesBlock
        category="artist"
        limit={4}
        seeMoreUrl="/sounds"
        seeMoreText="see full discography"
      />
    </Layout>
  );
};

export default IndexPage;
