import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

import ExpandMore from "../../assets/expand_more_white_24dp.svg";

import * as styles from "./styles.module.scss";

const query = graphql`
  query {
    file(relativePath: { eq: "ld-jesse-prophet.jpg" }) {
      childImageSharp {
        desktop: gatsbyImageData(aspectRatio: 1.777777, layout: FULL_WIDTH)
        tablet: gatsbyImageData(aspectRatio: 1.777777, layout: FULL_WIDTH)
        mobile: gatsbyImageData(
          aspectRatio: 0.5625
          layout: FULL_WIDTH
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
  }
`;

const Hero = ({ withText = true }) => {
  const data = useStaticQuery(query);

  return (
    <div className={styles.hero}>
      <GatsbyImage
        alt="Image of keyboard player Jesse Fischer holding a keyboard in a warehouse"
        className={styles.heroImage}
        image={withArtDirection(getImage(data.file.childImageSharp.mobile), [
          {
            media: `(min-width: 768px)`,
            image: getImage(data.file.childImageSharp.tablet),
          },
          {
            media: `(min-width: 1024px)`,
            image: getImage(data.file.childImageSharp.desktop),
          },
        ])}
      />

      {withText && (
        <div className={styles.heroText}>
          <div className={styles.heroInnerWrapper}>
            <h1 className={styles.name}>Jesse Fischer</h1>
            <div>
              <h2 className={styles.role}>pianist.</h2>
              <h2 className={styles.role}>producer.</h2>
              <h2 className={styles.role}>mixer.</h2>
            </div>
          </div>
        </div>
      )}
      {withText && (
        <div className={styles.expandContainer}>
          <a
            onClick={() =>
              document
                .querySelector("#press-quotes")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <ExpandMore className={styles.expand} />
          </a>
        </div>
      )}
    </div>
  );
};

export default Hero;
