import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";

import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import * as styles from "./styles.module.scss";

const query = graphql`
  query {
    allContentfulQuotes(sort: { order: ASC, fields: updatedAt }) {
      nodes {
        quote {
          raw
        }
        attr
        logo {
          gatsbyImageData(height: 40)
        }
      }
    }
  }
`;

const QuotesBlock = ({
  limit,
  headline,
  seeMoreUrl,
  seeMoreText,
  background,
}) => {
  const [activeQuote, setActiveQuote] = useState(0);
  const {
    allContentfulQuotes: { nodes: quotes },
  } = useStaticQuery(query);

  const slicedQuotes = limit ? quotes.slice(0, limit) : quotes;

  useEffect(() => {
    const t = setTimeout(() => {
      setActiveQuote(
        activeQuote < slicedQuotes.length - 1 ? activeQuote + 1 : 0
      );
    }, 3000);
    return () => clearTimeout(t);
  }, [activeQuote]);

  return (
    <article
      style={background && { background }}
      className={styles.quotesArticle}
      id="press-quotes"
    >
      {headline && <h1>{headline}</h1>}
      <div className={styles.logosContainer}>
        {slicedQuotes.map((quote, index) => (
          <div
            className={cx({ [styles.active]: index === activeQuote })}
            onClick={() => setActiveQuote(index)}
            key={index}
          >
            <GatsbyImage
              image={getImage(quote.logo)}
              alt={`${quote.attr} logo`}
            />
          </div>
        ))}
      </div>

      <div className={styles.quotesSlider}>
        {slicedQuotes.map((quote, index) => (
          <div
            className={cx(styles.quotesInnerWrapper, {
              [styles.active]: index === activeQuote,
            })}
            key={index}
          >
            {documentToReactComponents(JSON.parse(quote.quote.raw), {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <div className={styles.quote}>{children}</div>
                ),
              },
            })}
            <div className={styles.quoteAttr}>{quote.attr}</div>
          </div>
        ))}
      </div>

      {seeMoreUrl && seeMoreText && (
        <div className={styles.readMore}>
          <span className={styles.linkWrapper}>
            <Link to={seeMoreUrl}>{seeMoreText}</Link>
          </span>
        </div>
      )}
    </article>
  );
};

export default QuotesBlock;
